$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #64B6B1;
$body-color: #3c3c3c;
$headings-color: #5F5E56;
$headings-font-weight: normal;
$headings-font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary: #64B6B1;

$link-color: $primary;
$link-hover-color: $primary;

$navbar-padding-y: 0;
$navbar-nav-link-padding-x: 1rem;

$nav-tabs-link-active-bg: #64B6B1;

$navbar-light-color:                #5F5E56;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       rgba(#fff, .3);

$navbar-bg: #D9CFB4;
$navbar-link-bg: $navbar-bg;
$navbar-link-hover-bg: #64B6B1;
$navbar-link-active-bg: #64B6B1;

$hamburger-color: #fff;

$story-headings-color: $headings-color;

$ads-backdrop-color-1: #61b8b3;

@import "../../fonts/lato";
@import "../../fonts/open-sans";
@import "../../magazine";

body {
  background-image: url(../../../img/unnuetzes/background.png);
  background-repeat: repeat;
  background-position: left top;
}

@include media-breakpoint-up(md) {
  .brand-logo img {
    height: 74px;
  }
}

.brand-slogan {
  color: #fff;
  @include font-size(1.5rem);
  font-weight: $font-weight-light;
  padding-left: 20px;
  display: none;

  @include media-breakpoint-up(lg) {
    display: inline-block;
  }
}

.navbar-top {
  background-color: #5F5E56;
}

.navbar {
  background-color: $navbar-bg;
  border-bottom: 1px solid #4e4d46;

  .nav-link {
    @include font-size(1rem);
    letter-spacing: 1px;
    text-transform: uppercase;

    @include hover-focus {
      background-color: $nav-tabs-link-active-bg;
    }
  }
}

.navbar.navbar-expand  {
  .nav-item {
    @include nav-item-separator(#fff, $navbar-link-font-size);
  }
}

.navbar .nav-item.pur-mgmt .nav-item:last-of-type {
  border-bottom: 1px solid #ddd;
}

@include media-breakpoint-between(md, lg) {
  .navbar-expand-md .navbar-nav .nav-link {
    letter-spacing: normal;
    padding-right: .375rem;
    padding-left: .375rem;
  }
}


@include media-breakpoint-down(sm) {
  // Horizontal lines between open menu items
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    .navbar-nav > .nav-item + .nav-item {
      border-top: 1px solid #fff;
    }
  }
}

.sidebar .card.filled {
  background-color: $navbar-bg;
}

.section-caption {
  color: #000;
  font-family: $font-family-sans-serif;
  font-weight: normal;
}

.navbar.navbar-expand .nav-item+.nav-item::after {
  display: none;
}

.article-head {
  h1 {
    font-weight: $font-weight-normal;
    letter-spacing: 1px;
  }

  p {
    color: #777;
    font-weight: 600;
  }
}

.article-story {
  h3, h4 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
  }

  h3 {
    @include font-size($font-size-base * 1.7);
  }
}
